import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "UI テストの自動化に Magic Pod を導入した話",
  "date": "2021-01-15T09:01:26.000Z",
  "slug": "entry/2021/01/15/180126",
  "tags": ["medley"],
  "hero": "./2021_01_15.png",
  "heroAlt": "Magic Pod"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。インキュベーション本部の QA エンジニアの米山です。主に CLINICS アプリの QA を担当しています。メドレーには 2020 年 8 月に入社しました。`}</p>
    <p>{`今回は入社してまず行ったことの一つ、リグレッションテストの自動化と、そのために導入した Magic Pod というツールについて、経緯や導入してみた結果をご紹介したいと思います。`}</p>
    <h1>{`CLINICS とは`}</h1>
    <p>{`私の所属するチームで開発している`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`というプロダクトはアプリでオンライン診療や、クリニック・病院から処方箋を発行してもらうことができ、オンライン上で診察からお薬の受け取りまで完結できるサービスです。
プラットフォームは iOS と Android のネイティブアプリ、それから同様のサービスを Web ブラウザからも利用することが出来ます。`}</p>
    <h1>{`QA／リリース周りの状況`}</h1>
    <p>{`CLINICS の開発組織に QA エンジニアがジョインしたのは昨年（2020 年）ですが、サービス自体は 2016 年にローンチされています。`}</p>
    <p>{`本組織ではリリース前に行うリグレッションテストについては、開発メンバを中心にチーム全体で行う文化があります。
アプリのリリースは隔週で行っており、その都度開発メンバ自身によってテストが行われていましたが、自動化された UI テストは存在していませんでした。`}</p>
    <p>{`メドレーでは QA エンジニアがジョインして間もないため、やりたいこと・やるべきことは多岐にわたる中でまず何から着手するべきか検討しました。`}</p>
    <p>{`QA プロセスの策定・改善から、新機能をリリースまで推進するための QA 活動もあり、並行して幾つか動いている中でテスト自動化をどのタイミングで、どうやってスタートするか悩みました。`}</p>
    <h1>{`バリューから考える`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210115/20210115142126.png",
      "alt": "20210115142126.png"
    }}></img>
    <p>{`メドレーのバリューはこの三つです。これらのバリュー視点で考えてみました。`}</p>
    <p>{`「凡事徹底」として、リリース前のリグレッションテストをしっかり行うことは当然のこととして考えられます。`}</p>
    <p>{`「中央突破」の視点ではどうかと考えると、やはりテストプロセスにおいて、特にリリース毎に繰り返し作業となるリグレッションテストを自動化することは王道であり、ベストプラクティスの一つだと考えられます。
そのため自動化は優先度高く進めるべきではあります。`}</p>
    <p>{`残る一つ「未来志向」については、例えば 1~2 年後やその先を考えて、リグレッションテストが自動化されているべきか否かで言うとやはり Yes です。`}</p>
    <p>{`また、別の観点として、現在はわずか 2 人の QA エンジニアに対して、複数のプロダクトが存在している状況で、QA エンジニアがアサインされていないプロダクトも多くあります。`}</p>
    <p>{`私自身も昨年 10 月からアプリ・基盤チームに異動したこともあり、今後についてもまた体制が変わっていくことは十分に考えられました。`}</p>
    <p>{`そんな状況下では、仮に UI テストを自動化した環境を用意できたとして、その後に担当者が不在になった場合も考慮しておく必要があります（自動テストにおいて、担当が不在になったことでメンテされなくなり形骸化するケースはよくある話です）。`}</p>
    <p>{`そのため、仮に実装者が不在となった後でも誰かに引き継ぎやすく、またエンジニア以外でも運用できる環境が望ましいと考えました。そういった観点でツールとしては基本ノーコードでもメンテできる Magic Pod は有力な候補となりました。`}</p>
    <p>{`これらをまとめると、以下のような結論に至りました。`}</p>
    <ul>
      <li parentName="ul">{`テストの自動化は推進した方が良い`}</li>
      <li parentName="ul">{`ただし、他のメンバでもメンテしやすい環境を選定する`}</li>
    </ul>
    <p>{`ただし QA としてやるべき事が沢山ある中で、テスト自動化だけに専念できる状況ではありません。
そのためなるべく他タスクと並行して小コストで進められる事も重要な要素でした。`}</p>
    <p>{`自動化された UI テストは全くない事や、他のテストの密度も鑑みると、なるべく早い段階で一定の自動テスト環境は用意したいという想いもありました。`}</p>
    <p>{`これらの状況も踏まえ、ツールを選定・トライアルしてみた結果、Magic Pod を導入することに決めました。`}</p>
    <h1>{`Magic Pod の紹介`}</h1>
    <p><a parentName="p" {...{
        "href": "https://www.magic-pod.com/"
      }}>{`Magic Pod`}</a>{`について、サービス自体の詳細は割愛しますが、端的にいうとクラウド環境かつ GUI から UI テストの実装及び実行を行うことができるツールです。`}</p>
    <p>{`GUI で自動テストが実装できるツールだと、`}<a parentName="p" {...{
        "href": "https://autify.com/ja"
      }}>{`Autify`}</a>{`なども有名です。
Autify はブラウザ向けのツールですが、実装方法は Magic Pod とは少し異なり、操作をレコーディングしてテストシナリオが自動で生成される形が基本です。`}</p>
    <p>{`一方、Magic Pod は以下のようにアプリの画面をまずキャプチャで取り込み、そこからテストで使いたい項目を選択し、シナリオにドラッグアンドドロップしていくことでテストシナリオを生成することができます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210115/20210115141554.png",
      "alt": "20210115141554.png"
    }}></img>
    <p>{`ログインなど、複数のテストで使う部分は共通化しておきます。`}</p>
    <p>{`テスト対象が iOS アプリであろうと、Android アプリやブラウザであろうと基本的に同じ I/F からテストの生成・メンテが出来ることは大きな強みの一つです。`}</p>
    <p>{`また、テストで使用するフィールドの要素を選択可能なことも、状態変化に強いテストとする上での強みとなります。`}</p>
    <p>{`例えば「調剤薬局名でさがす」というテキストフィールドに対して、そのテキストを使うのか、ID なのか、テキストフィールドなのか xpath なのかといった所です。`}</p>
    <p>{`そのため、`}</p>
    <ul>
      <li parentName="ul">{`テキストが頻繁に変わるような場所（例えば日付など）ではテキストを使わない`}</li>
      <li parentName="ul">{`アプリ内部でリファクタリングなどが動いている場合であれば逆に ID は変わる可能性が高いため、テキストで指定する`}</li>
    </ul>
    <p>{`UI テストを作り込む上では当然のことではありますが、上記のような工夫によりテストの成功率を上げることができます。`}</p>
    <h1>{`導入してみて`}</h1>
    <p>{`トライアル中は探りながらの部分はあったものの、慣れると実装工数は非常に短期間で実装でき、トータルでも iOS で 2~3 週間（オンボーディング含む）、Android の UI テストについては実質 2~3 日で基本的なテストシナリオの自動化を行う事ができました。`}</p>
    <p>{`その後、運用しながら落ちやすいテストの改修を行ったり、運用が安定してからは CI にも連携しています。`}</p>
    <p>{`UI テストの運用においては定期的に実行することは非常に重要なことですが、Magic Pod の場合、Bitrise では UI 上から設定でき、Circle CI に対してもドキュメントを参照しながら比較的容易に設定できます。`}</p>
    <p>{`実際、昨年 1 クォーター運用してみて、幾つかのクラッシュをリリース前に検知してくれました。`}</p>
    <p>{`また、私自身、過去には XCTest における UITest(`}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/xcode/testing_your_apps_in_xcode"
      }}>{`Testing Your Apps in Xcode`}</a>{`)や`}<a parentName="p" {...{
        "href": "https://appium.io/"
      }}>{`Appium`}</a>{`を使って UI テストを運用していたため、以下ではそれら他ツールとの比較も含めて紹介してみたいと思います。`}</p>
    <h2>{`実装コスト`}</h2>
    <p>{`実装コストにも初期構築と、その後のメンテコストで分かれますが、他のツールと比較して、大きく異なるのは初期構築コストだと思います。`}</p>
    <p>{`Magic Pod については環境構築コストは非常に低コストで行うことができます（基本的な部分は 1 日あれば十分だと思います）。
またテストのレポーティングやキャプチャ機能なども標準で付いていますので、この辺りも自前で頑張る必要はありません。`}</p>
    <p>{`次にメンテコストですが、例えば XCUITest ではまずビルドを行い、debug して各ボタンなどの要素の ID などを確認し、それらを用いてコーディングしていました。
Magic Pod では一度アプリをアップロードして、スキャンすることで画面の要素を一括で取得でき、その中から操作したい要素を選択することができます。`}</p>
    <p>{`そのためこちらもコストはだいぶ下がります。ただ、この部分については他のツールや言語でも慣れればそう時間はかからないのでもしかしたら大差ないかもしれません。`}</p>
    <p>{`あえて言うと debug で ID を確認する手間が楽になる、実装したテストを試して実行するのが容易（ビルド待ちの時間がない）といった辺りでしょうか。`}</p>
    <h2>{`運用コスト`}</h2>
    <p>{`UI テストといえば Flaky なテスト（落ちたり落ちなかったりするテスト）に悩まされることは多いですが、運用してみると最初の内はそういったこともありましたが、現状ではほぼ起きていません。`}</p>
    <p>{`これは Magic Pod に限った話ではありませんが、`}</p>
    <ul>
      <li parentName="ul">{`クラウド上で実行されることで環境要因で落ちることは稀`}</li>
      <li parentName="ul">{`落ちた時には自動でリトライされる`}</li>
      <li parentName="ul">{`ビルドも CI 上で実行している`}</li>
      <li parentName="ul">{`実行はメンバが活動していない時間帯に行っている`}</li>
    </ul>
    <p>{`といった辺りが要因かと思います。`}</p>
    <p>{`また Magic Pod のようなツールを使っている場合に助かる部分としては、Xcode など、UI テストに必要なツールのアップデートに対するメンテが不要ということも挙げられます。`}</p>
    <h2>{`逆に少し辛い所`}</h2>
    <p>{`ここまで Magic Pod の良い部分を多く書きましたが、逆にこのような GUI でのテストツールを使うことで少しやり辛い点も紹介しておきたいと思います。`}</p>
    <h4>{`1. テストコードのレビュー`}</h4>
    <p>{`テストコード（ケース）は Magic Pod 上で管理されているため、PR レビューなどのプロセスを行うことができません。
そのため、ケースの修正に対して、反映させる前にレビューしてもらいたい場合は、テストケースをコピーしてから編集するなど少し工夫が必要になるかと思います。`}</p>
    <p>{`現状では困ることはありませんが、複数人で同一のプロジェクトに対して運用したい場合は少し煩雑になりそうです。`}</p>
    <h4>{`2. テストコードの管理`}</h4>
    <p>{`自動テストにおいて、テスト結果に影響が出る仕様変更が入るような場合、仕様変更に対するテストコードの修正は開発と並行して用意しておき、プロダクトへの変更がマージされるタイミングで同時にテストコードの修正もマージしたいケースがあります。`}</p>
    <p>{`Magic Pod では GitHub 上でテストコードを管理していないため、このようなケースへの対応を自動で行うことが難しく、予めテストケースを分けて用意しておき、実装がマージされた後に手動で置き換えるか、マージされた後に影響のあるテストケースを修正するといった手動でのプロセスが必要になります。`}</p>
    <p>{`現時点で気になったのは上記の 2 点ですが、これらも今後改善されていく可能性は大いにありますし、プロセスの中での工夫次第で対処も可能かと思います。`}</p>
    <h2>{`その他`}</h2>
    <p>{`基本的に UI テストを自動化する上で気をつけるべきことやアンチパターンはどんなツールを使っても同じです。
他のツールでは難しいことが、このツールでは実現出来るということも稀で、時にはプロダクト側で手を入れる必要もあります。
どんなツールであれ、何かしら工夫すれば達成出来ることが多いため、違いが出るのは実装や運用、オンボーディング等のコスト部分が最も大きいのではないかと感じています。`}</p>
    <h1>{`周囲のサポート`}</h1>
    <p>{`テスト自動化を行う場合（だけではないですが）、周囲の理解を得ることは大事な部分ですが、チームメンバは皆前向きで興味を持ってくれて進めやすい環境でした。`}</p>
    <p>{`特に CI 連携の部分では iOS/Android の開発の方にもサポートしていただき大変助かりました。`}</p>
    <p>{`そして Magic Pod については、数年前から運用している株式会社ノハナの武田さんにも事前に話を伺ったり、オンボーディング中は質問させていただいたりしました（ありがとうございました！）。`}</p>
    <p>{`また Magic Pod の伊藤様には導入時からトラブルシューティングに多大なサポートをいただいています。`}</p>
    <p>{`Circle CI に入れ込む際には、ちょっと詰まった点があり伊藤様とメールでやり取りしていたのですが、その日のうちに`}<a parentName="p" {...{
        "href": "https://www.trident-qa.com/magic-pod-circleci/"
      }}>{`ドキュメント`}</a>{`がアップされたり、
とある環境下で不明なエラーが出ていて相談した際には、ストアから CLINICS アプリをダウンロードして試していただいたり、とにかくいつも迅速かつご丁寧な対応が印象的でした。`}</p>
    <p>{`まだ QA チームもないような少人数の状況では、こういったトラブルに対して相談でき、共に解決法を探れる方がいるという意味でも非常に心強いです。`}</p>
    <h1>{`今後について`}</h1>
    <p>{`アプリの UI テストについて、改善していきたいことはまだまだ沢山あるのですが、現状でも基本的なテストは用意できているため、じっくり腰を据えて改善していきたいと考えています。`}</p>
    <p>{`また現在はブラウザのテスト自動化を進めています。メドレーの CLINICS 以外のプロダクトの多くは Web ブラウザをプラットフォームとしているため、Web についてはプロダクトを跨いだ活動も行っていければと考えています。`}</p>
    <p>{`長くなりましたが、最後までお読みいただきありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      